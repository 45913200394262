<template>
  <div class="elite-tabs-wrapper-content">
    <div class="resume-header">
      <div class="resume-header-image">
        <img
          v-if="user.profile_image"
          :src="`${user.profile_image}`"
          class="argon-image"
        />
        <img v-else src="/img/add-image.svg" class="default" />
      </div>
      <div class="resume-header-text">
        <h3>{{ user.firstname }} {{ user.lastname }}</h3>
        <ul>
          <li>{{ user.email }}</li>
        </ul>
        <div class="resume-header-text-button">
          <button class="btn" @click="onUpdateUser">
            <span> {{ $t("COMMON.UPDATE") }} </span>
            <i class="far fa-edit"></i>
          </button>
        </div>
      </div>
    </div>
    <div class="resume-list">
      <div class="resume-list-inner">
        <dl class="width_1-2">
          <dt>
            {{ $t("COMMON.PHONE") }}
          </dt>
          <dd>
            {{
              user.phone_type
                ? $t(`COMMON.PHONE_TYPE_${user.phone_type}`)
                : null
            }}
            {{ user.phone }}
            {{
              user.phone_extension ? ` (ext: ${user.phone_extension})` : null
            }}
          </dd>
        </dl>

        <dl class="width_1-2">
          <dt>
            {{ $t("COMMON.OTHER_PHONES") }}
          </dt>
          <dd>
            <span v-for="(line, index) in user.other_phones" :key="index">
              {{ $t(`COMMON.PHONE_TYPE_${line.type}`) }}:
              {{ line.phoneNumber }}
              {{ line.extension ? ` (ext: ${line.extension})` : null }} <br />
            </span>
          </dd>
        </dl>

        <dl class="width_1-2">
          <dt>{{ $t("COMMON.LOCALE") }}</dt>
          <dd>
            {{
              user.locale
                ? $t(`COMMON.LOCALE_${user.locale.toUpperCase()}`)
                : null
            }}
          </dd>
        </dl>

        <dl class="width_1-2">
          <dt>{{ $t("USERS.ACTIVE") }}</dt>
          <dd>
            <icon-check :checked="!!user.active" />
          </dd>
        </dl>

        <dl class="width_1-2" v-if="$currentUser() && $currentUser().is_staff">
          <dt>{{ $t("USERS.IS_STAFF") }}</dt>
          <dd>
            <icon-check :checked="!!user.is_staff" />
          </dd>
        </dl>

        <dl
          class="width_1-2"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ROLES)"
        >
          <dt>{{ $t("COMMON.ROLE") }}</dt>
          <dd>
            <object-link v-if="user.roles[0]" :object="user.roles[0]" />
            <span v-else> {{ $t("COMMON.NONE") }} </span>
          </dd>
        </dl>

        <dl class="width_1-2">
          <dt>{{ $t("USERS.RESTRICT_TO_LOCATIONS") }}</dt>
          <dd>
            <icon-check :checked="!!user.restrict_to_locations" />
          </dd>
        </dl>

        <dl class="width_1-2" v-if="user.restrict_to_locations">
          <dt>{{ $t("COMMON.LOCATIONS") }}</dt>
          <dd>
            <locations :locations="user.allowedLocations" />
          </dd>
        </dl>

        <dl
          class="width_1-2"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)"
        >
          <dt>{{ $t("COMMON.ORGANIZATION") }}</dt>
          <dd>
            <organization :organization="user.organization" />
          </dd>
        </dl>

        <dl
          class="width_1-2"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_RESELLERS)"
        >
          <dt>{{ $t("COMMON.RESELLER") }}</dt>
          <dd>
            <reseller :reseller="user.reseller" />
          </dd>
        </dl>

        <dl class="width_1-2">
          <dt>{{ $t("COMMON.CREATED_AT") }}</dt>
          <dd v-if="user.created_at">
            {{ $timeZoneDateFormat(user.created_at) }}
          </dd>
        </dl>

        <dl class="width_1-2">
          <dt>{{ $t("COMMON.UPDATED_AT") }}</dt>
          <dd v-if="user.updated_at">
            {{ $timeZoneDateFormat(user.updated_at) }}
          </dd>
        </dl>

        <div class="width_1-2 title" style="display: none">
          <h3>{{ $t("COMMON.BILLING_ADDRESS") }}</h3>
        </div>

        <billing-informations-view
          style="display: none"
          :billingInformations="user"
        />
      </div>
    </div>
  </div>
</template>
<script>
import IconCheck from "@/components/IconCheck.vue";
import BillingInformationsView from "@/components/BillingInformationsView.vue";

export default {
  name: "user-view-global",

  components: { IconCheck, BillingInformationsView },

  props: ["user"],

  data() {
    return {};
  },

  computed: {},

  methods: {
    onUpdateUser() {
      this.$emit("onUpdateUser");
    },
  },

  mounted() {},

  watch: {
    user(user) {},
  },
};
</script>
